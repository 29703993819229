.gift-inner-area-wrap {
  display: flex;
  align-items: center;
  height: 70vh;
  width: 747px !important;
  & h3 {
    text-align: center;
    margin-bottom: 40px;
  }
}
.gift-area-content-right {
  width: 100%;
  min-height: 230px;
  //   background: #8b4c4c10;
}

.hjgkhjghfdjnkvcmbklhjg img {
  width: 100%;
}
button.gift-submit-button-area {
  border: none;
  background: #5756d8;
  padding: 13px 30px;
  border-radius: 5px;
  color: #fff;
  display: block;
  width: 100%;
}
.hjdhkdbmvjkfgb {
  margin-bottom: 15px;
}
.hdjnmvdhbdnmviuygbhc {
  height: 80vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 40px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 60px;
  overflow: hidden;
  margin-top: 40px;
  &:after {
    content: "";
    position: absolute;
    background: #292d32b2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .eurkjfhdhfjsufg {
    z-index: 99;
    position: relative;
    button {
      background: #fff;
      border: none;
      padding: 12px 30px;
      font-weight: 700;
      border-radius: 5px;
      margin-top: 15px;
      color: #5756d8;
    }
  }
}
.eurkjfhdhfjsufg h3 {
  color: #fff !important;
  margin-bottom: 15px;
  font-size: 40px;
}
 
.hfhdjlfdhbjn {
  position: relative;
  margin-bottom: 30px;
  /* margin-top: 10px; */
  padding-top: 3px;
  &:after {
    content: "";
    width: 30%;
    height: 2px;
    position: absolute;
    background: #fff;
    left: 0;
    bottom: -15px;
  }
  i {
    color: #fff;
  }
  p {
    color: #fff;
  }
}
