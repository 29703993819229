.student-profile-view-main-area {
  margin: 40px 0;
  .student-profile-title-area {
    h3 {
      font-weight: 700;
      font-size: 28px;
    }
  }
}

.stident-profile-content-fgbhdnjmk {
  text-align: center;
  background: #fff;
  box-shadow: 0px 10px 20px rgb(182 182 182 / 10%);
  margin: 30px 0;
  padding: 40px;
  position: relative;
  border-radius: 10px;
}
.student-course-profile-bottom-edfswr {
  width: 70%;
  margin: 20px auto;
}
.student-course-info-wsxcde {
  border: 1px solid #e5e5f0;
  padding: 20px;
  border-radius: 10px;
  p {
    color: #74788d;
  }
  font-weight: 700;
}
.student-profile-image-area-top-tgbnmkiuh {
  img {
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
    border-radius: 50%;
  }
  h4 {
    font-weight: 700;
    margin-bottom: 5px;
  }
}
.edit-profile-student-part {
  position: absolute;
  right: 40px;
  top: 30px;
  a {
    display: inline-block;
    background: #583bab;
    padding: 10px 30px;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
  }
}
.course-list-profile-page.student-courses-page {
  .dashboard-sidebar-single-item {
    padding: 15px 0;
    .course-sidebar-single-item.ujhgfbsdvzbsd {
      background: #fff;
      border: 1px solid #f3f3f3;
    }
  }
}
.student-buy-course-list {
  box-shadow: 0px 10px 20px rgb(182 182 182 / 10%);
  background: #fff;
  border-radius: 10px;
  padding: 25px;
}
.student-buy-course-list-title h4 {
  font-weight: 700;
  margin-bottom: 10px;

  padding-bottom: 15px;
}
.course-sidebar-single-item-main-wrap.ujhgfbsdvzbsd.kjhgfedxgfd {
  border-bottom: 1px solid #ced4da;
  &:last-child {
    border-bottom: none;
  }
}
.student-buy-course-list-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cecece;
}
.course-sidebar-single-item-left-sdfghcjv.easfv > p {
  font-weight: 700;
  font-size: 16px;
}

.course-sidebar-single-item-right-sdfghcjv.rthbgxh > p {
  font-weight: 700;
  color: #5756d8;
}

.student-result-filtering-area-hdmbfkbn > select {
  font-size: 14px;
  font-weight: 600;
  color: #1b3f73;
  background: #f8fbff;
  border-color: #1b3f73;
  border-radius: 10px;
}
.student-course-info-wsxcde p {
  font-weight: 400;
}
.tab-bar-icon-box i.bx.bx-menu:before {
  content: "\eb5f" !important;
}
.student-course-info-wsxcde {
  border: 1px solid #e5e5f0;
  padding: 20px;
  border-radius: 10px;
  font-weight: 700;
  margin-bottom: 15px;
}
