.pyramid-item.first-row {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.pyramid-item.first-row .single-item {
  border: 1px solid #f7b941;
  width: 250px;
  text-align: center;
  padding: 8px 20px;
  border-radius: 5px;
  position: relative;
}
.pyramid-item-second-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  gap: 25px;
  left: 68px;
}
.pyramid-tree-main-area {
  width: 940px;
  margin: auto;
}
.pyramid-item.first-row .single-item:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 21px;
  left: 124px;
  background: #f7b941;
  bottom: -21px;
}
.pyramid-item-left .single-item {
  border: 1px solid #f7b941;
  width: 250px;
  text-align: center;
  padding: 8px 20px;
  border-radius: 5px;
  position: relative;
}
.pyramid-item-left .single-item:after {
  width: 2px;
  height: 20px;
  content: "";
  background: #f7b941;
  position: absolute;
  top: -20px;
  left: 124px;
}
.pyramid-item-right .single-item {
  border: 1px solid #f7b941;
  width: 250px;
  text-align: center;
  padding: 8px 20px;
  border-radius: 5px;
  position: relative;
}
.pyramid-item-right .single-item:after {
  width: 2px;
  height: 20px;
  content: "";
  background: #f7b941;
  position: absolute;
  top: -20px;
  left: 124px;
}
.pyramid-item-second-row:after {
  content: "";
  position: absolute;
  width: 57.6%;
  height: 2px;
  background: #f7b941;
  top: -20px;
  margin: auto;
  left: 125px;
}
.pyramid-item-left-inner-third-row {
  display: flex;
  margin-top: 40px;
  position: relative;
  left: -136px;
  gap: 15px;
  align-items: baseline;
}
.pyramid-item-left-inner-third-row:after {
  content: "";
  position: absolute;
  width: 51.9%;
  height: 2px;
  background: #f7b941;
  top: -20px;
  left: 125px;
}
.pyramid-item-left .single-item:before {
  width: 2px;
  height: 20px;
  position: absolute;
  content: "";
  background: #f7b941;
  bottom: -21px;
}
.pyramid-tree-main-area h4 {
  font-size: 13px;
  padding: 2px 0;
}
.single-item.third-row-left:before {
  opacity: 0;
}
.single-item.fixed-asset-turnover {
  top: 35px;
}
.single-item.inventory-turnover {
  top: 70px;
}
.inventory-turnover-left-items-wrap {
  position: relative;
}

.inventory-turnover-left-items-inner-wrap {
  position: absolute;
  left: -115%;
  transform: rotate(90deg);
  top: 30px;
}
.inventory-turnover-left-single-item-wrap {
  transform: rotate(-90deg);
  position: relative;
}
.inventory-turnover-left-single-item-wrap:after {
  width: 2px;
  height: 140px;
  background: #f7b941;
  position: absolute;
  content: "";
  top: -21px;
  right: -10px;
}
.inventory-turnover-left-items-inner-wrap .single-item {
  top: -40px;
  margin-bottom: 20px;
  margin-left: 0;
  left: -10px;
}
.inventory-turnover-left-items-inner-wrap .single-item:after {
  width: 20px;
  height: 2px;
  content: "";
  background: #f7b941;
  position: absolute;
  top: 50%;
  right: -20px;
  left: unset;
}
.inventory-turnover-left-items-inner-wrap .single-item:before {
  display: none;
}
.inventory-turnover-left-single-item-wrap:before {
  width: 20px;
  height: 2px;
  background: #f7b941;
  content: "";
  position: absolute;
  top: 31%;
  right: -28px;
}
.inventory-turnover-left-items-wrap.right-items-wrap-ghbnjmk {
  top: 35px;
}
.inventory-turnover-left-items-inner-wrap.right-items-wrap-ghbnjmk {
  left: unset;
  right: -115%;
  top: 75px;
}
.inventory-turnover-left-single-item-wrap.right-items-wrap-ghbnjmk:after {
  width: 2px;
  height: 60%;
  background: #f7b941;
  position: absolute;
  content: "";
  top: -20px;
  right: unset;
  left: -30px;
}
.inventory-turnover-left-single-item-wrap.right-items-wrap-ghbnjmk:before {
  width: 20px;
  height: 2px;
  background: #f7b941;
  content: "";
  position: absolute;
  top: 4%;
  right: unset;
  left: -50px;
}
.inventory-turnover-left-single-item-wrap.right-items-wrap-ghbnjmk
  .single-item:after {
  width: 20px;
  height: 2px;
  content: "";
  background: #f7b941;
  position: absolute;
  top: 50%;
  right: unset;
  left: -21px;
}
.single-item {
  border-color: #f7b941 !important;
  background: #fffefb;
}
.gvhbjnkmkjhbgv .single-item {
  margin-bottom: 19px;
}
.single-item.inventory-turnover.right-items-wrap-ghbnjmk:before {
  display: none;
}
.pyramid-item-right > .single-item:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  background: #f7b941;
  bottom: -21px;
}
.type-of-ratio > div > div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}
.pyramid-main-title-wra-grgbvcrtyhg {
  text-align: center;
  margin-bottom: 30px;
}
.pyramid-main-title-wra-grgbvcrtyhg h3 {
  font-size: 24px;
  font-weight: 700;
}
.title-radio-button-pyramid h5 {
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: 700;
  text-align: center;
}
.type-of-ratio p {
  font-size: 10px;
  line-height: 14px;
  color: #556ee6;
  text-align: center;
  padding: 0px 10px;
}
.type-of-ratio > div > div > label {
  font-size: 14px;
}
.pyramid-ration-content-main-area {
  position: absolute;
  bottom: 0;
  padding: 30px;
}
.pyramid-ration-content-main-area p {
  font-size: 13px;
  color: #6a7187;
}
.case-method-list td {
  font-size: 13px;
}
th.fghbjnkmjhgfc {
  position: relative;
  top: -15px;
}

.drthbvcdftyuiuhjuyg {
  padding: 20px 40px;
}

.drthbvcdftyuiuhjuyg h3 {
  font-weight: 700;
  margin-bottom: 30px !important;
}
.industry-ratio-left h3 {
  font-weight: 700;
  margin-bottom: 15px;
}
.industry-ratio-left p {
  font-size: 14px;
}
.industry-ratio-left h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.industry-ratio-left p {
  font-size: 14px;
}

.calculation-info h6 span {
  font-weight: 700;
}

.calculation-info h6 {
  font-size: 14px;
  margin-bottom: 5px;
}

.calculation-info p span {
  font-weight: 700;
}

.calculation-info p {
  font-size: 13px;
  line-height: 21px;
  padding-left: 15px;
}
.header-main-navbar-main-area ul li a {
  padding: 0px 20px !important;
}
.header-main-navbar-main-area {
  background: #fff !important;
  padding: 10px 0;
  box-shadow: 0px 0px 10px 0px #dddddd40;
}
tr.table-ration-input-header-top-shjbnskbg {
  border: 1px solid transparent;
  border-bottom: 1px solid #ddd;
}
th.text-center.ghbmv-jsvuhjnc-jb {
  position: relative;
}

th.text-center.ghbmv-jsvuhjnc-jb:after {
  width: 100%;
  height: 4px;
  background: #a589df;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
}
th.text-center.ghbmv-jsvuhjnc-jb.hrdfgsgfg:after {
  background: #bdd1f9;
}
.case-method-list .table > :not(:first-child) {
  border-top: 1px solid #ddd;
}
tbody.table-ration-input-body-wrap-hbbcfbdbfvbj tr.ratio-single-info {
  width: 33% !important;
}
tbody.table-ration-input-body-wrap-hbbcfbdbfvbj
  .table-ration-input
  td:first-child {
  width: 30% !important;
}
.apexcharts-legend-series > span:first-child {
  width: 15px !important;
  height: 15px !important;
  border-radius: 3px !important;
  position: relative;
  top: 3px !important;
}
.comparative-ratio-graph p {
  color: #f1b44c;
  font-size: 16px;
  margin-bottom: 25px;
}
.comparative-ratio-graph-box {
  box-shadow: 0px 0px 10px #eceff1cc;
  padding: 10px;
  border-radius: 10px;
}
.table-responsive.finsasdsijk
  > table
  > thead
  > tr:first-child
  > th:first-child {
  width: 40%;
}
.table-responsive.finsasdsijk > table > thead > tr:last-child > th:first-child {
  width: 10%;
}
.table-responsive.finsasdsijk
  > table
  > thead
  > tr:last-child
  > th:nth-child(3) {
  width: 10%;
}
.table-responsive.finsasdsijk
  > table
  > thead
  > tr:last-child
  > th:nth-child(5) {
  width: 10%;
}
