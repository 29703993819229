.error-main-area-hdfskjdfbschdb p {
  color: red;
  display: flex;
  align-items: center;
  gap: 6px;
  i {
    font-size: 18px;
  }
}
.success-message-main-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  text-align: center;
  .success-message-inner-wrap {
    img {
      margin-bottom: 10px;
    }
    h4 {
      margin-bottom: 5px;
    }
    p {
      color: #64748b;
    }
  }
}
