.maac-loader-main-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.maac-loader-inner-wrap {
    width: 70px;
    animation-name: loader;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.mb-3.jkfhgsuhgjbygbhvdgb-s button {
    border: none;
    background: #0043ff2e;
    padding: 10px 20px!important;
    border-radius: 5px;
}

.mb-3.jkfhgsuhgjbygbhvdgb-s {
    text-align: right;
}
.maac-loader-inner-wrap img {
    width: 100%;
}

@keyframes loader {
    0% {
        transition: .3s;
        width: 70px;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    25% {
        transition: .3s;
        width: 50px;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transition: .3s;
        -moz-transition: .3s;
        -ms-transition: .3s;
        -o-transition: .3s;
    }
    50% {
        transition: .3s;
        width: 30px;
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
    75% {
        transition: .3s;
        width: 50px;
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
    75% {
        transition: .3s;
        width: 70px;
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
}