#EZDrawer #EZDrawer__container {
  position: fixed;
  visibility: visible;
  background: white;
  transition: all;
  box-shadow: 0 0 10px 5px rgb(0 0 0 / 10%);
  transform: translate3d(-80%, 0px, 0px) !important;
}

/* .dashboard-sidebar-nav-area.showNavbar #EZDrawer #EZDrawer__container {
  visibility: visible;
  transform: translate3d(0%, 0px, 0px) !important;
  width: 50px !important;
  background: transparent !important;
}
.dashboard-sidebar-nav-area.showNavbar
  #EZDrawer
  #EZDrawer__container
  .navbar-item-single-main-area-jddiu
  ul
  li
  a
  > *:last-child {
  display: none;
}
.dashboard-sidebar-nav-area.showNavbar
  #EZDrawer
  #EZDrawer__container
  .navbar-item-single-main-area-jddiu {
  overflow-y: scroll;
  height: 100vh;
  width: 100%;
}
.dashboard-sidebar-nav-area.showNavbar .accordion-body {
  position: absolute;
  width: 266px;
  background: #fff;
  padding-left: 50px;
} */
