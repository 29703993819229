.course-share-btn-area {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-bottom: 30px;
}
.course-share-btn-area a {
  display: flex;
  padding: 8px 20px;
  color: #bdbdbd;
  gap: 4px;
  align-items: center;
  text-decoration: none;
  border: 1px solid #bdbdbd;
  border-radius: 6px;
}
.course-details-content-main-area {
  h2 {
    margin-top: 20px;
    font-weight: 700;
    font-size: 48px;
  }
}
.details-page-inner-main-wrap > p {
  margin-top: 20px;
  margin-bottom: 0;
  color: #58595b;
}

.course-details-single-box-item-wrap {
  background: #fff;
  box-shadow: 5px 10px 30px rgb(182 182 182 / 15%);
  border-radius: 10px;
  padding: 20px 25px;
  margin-bottom: 15px;
  i {
    display: inline-block;
    margin-bottom: 12px;
  }
  h4 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
  }
}
.course-more-btn-area-see-more-btn {
  button {
    background: #f7b941;
    color: #fff;
    border: none;
    padding: 12px 25px;
    margin-bottom: 45px;
    border-radius: 5px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 9px;
    font-size: 14px;
    font-weight: 700;
    img {
      width: 20px;
    }
  }
}
.content-decription-coursware {
  background: #fff;
  padding: 50px;
  box-shadow: 5px 10px 30px rgb(182 182 182 / 15%);
  margin-bottom: 30px;
  p {
    font-size: 14px;
    font-weight: 400;
  }
  i {
    font-size: 18px;
    line-height: 31px;
    display: inline-block;
    margin-bottom: 10px;
    color: #8a8b95;
  }
}
a.back-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #1b3f73;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  span {
    i {
      font-size: 22px;
      position: relative;
      top: 3px;
    }
  }
}

.course-price-wrap-jdkbad {
  background: #fff;
  display: flex;
  align-items: center;
  gap: 20px;
  box-shadow: 5px 10px 30px rgba(182, 182, 182, 0.15);
  border: 1px solid #dfdbdb;
  padding: 10px 15px;
  border-radius: 10px;
}

.course-price-wrap-jdkbad h1 {
  color: #f7b941 !important;
  font-weight: 700;
}
.course-price-wrap-jdkbad i {
  color: #8a8b95;
}
.buy-course-main-wrap {
  display: flex;
  text-align: center;
  width: 100%;
  background: #fff;
  border: 2px solid #5756d8;
  color: #5756d8;
  border-radius: 10px;
  margin-top: 30px;
  padding: 4px;
}
.buy-course-main-wrap img {
  padding: 10px;
}
.buy-course-main-wrap button {
  border: none;
  background: none;
  text-align: center;
  width: 80%;
  font-size: 20px;
  font-weight: 600;
  color: #5756d8;
}
.buy-course-main-wrap.fdgf button {
  width: 100%;
  padding: 5px;
}
.course-author-main-area-wrap-jbd {
  text-align: center;
  background: #fff;
  box-shadow: 5px 10px 30px rgb(182 182 182 / 15%);
  margin-top: 50px;
  padding: 25px;
  border-radius: 10px;
}

.course-author-main-area-wrap-jbd {
  text-align: center;
  background: #fff;
  box-shadow: 5px 10px 30px rgba(182, 182, 182, 0.15);
  margin-top: 50px;
  padding: 25px;
  border-radius: 10px;
}

.course-author-main-area-wrap-jbd h5 {
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.5;
}

.course-author-main-area-wrap-jbd i {
  color: #8a8b95;
}

.course-author-main-area-wrap-jbd h6 {
  font-weight: 700;
  font-size: 24px;
  margin-top: 7px;
}

.additional-info-main-area-hbgjfkdl {
  text-align: left;
  // p {
  //   // font-style: italic;
  // }
}

.card-main-area-course-page-main-card.card {
  flex-direction: row;
  border: none !important;
  background: #fff;
  box-shadow: 5px 10px 30px rgb(182 182 182 / 15%);
  border-radius: 10px;
  padding: 20px 25px;
  img.card-img-left {
    width: 20%;
  }
  .card-body {
    width: 86%;
    flex: unset;
    .card-title.h5 {
      h3 {
        font-weight: 700;
      }
    }
  }
}
.details-page-inner-main-wrap {
  padding: 40px 0;
}
.login-after-navbar .navbar__link.header-login-btn .dropdown-menu.show {
  display: block;
  top: 30px !important;
  text-align: center;
  position: absolute !important;
  left: unset !important;
  right: 0 !important;
}
.login-after-navbar .navbar__link.header-login-btn.ghbfjnkdlfjhbnvmjnv {
  background: #fff;
  padding: 0px 0px;
}

.login-after-navbar .navbar__link.header-login-btn.ghbfjnkdlfjhbnvmjnv button {
  color: #000;
  display: flex;
  align-items: center;
  gap: 5px;
}
.login-after-navbar .dropdown-toggle::after {
  display: inline-block;
}
.login-after-navbar .header-navbar ul {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.popup-modal-main-wrap {
  padding: 50px;
  text-align: center;
  img {
    width: 100px;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 15px;
  }
  a.student-signup-btn-area {
    text-decoration: none;
    display: block;
    background: #5756d8;
    color: #fff;
    padding: 8px 30px;
    border-radius: 10px;
    font-size: 16px;
  }
  a.student-signup-btn-area-signup {
    margin-top: 10px;
    text-decoration: none;
    display: inline-block;
  }
}
.react-responsive-modal-modal {
  border-radius: 10px;
}
.course-gift-area-main-wrap {
  text-align: center;
  .gift-main-button {
    margin: 25px 0;
    a {
      color: #007bd3;
      text-decoration: underline;
      font-size: 16px;
    }
  }
}

// ========================================
.react-responsive-modal-modal.lafargeModal {
  max-width: unset !important;
  width: 520px;
  .lafarge-registration-title {
    margin-bottom: 30px;
    text-align: left;
    h3 {
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    p {
      font-size: 18px;
      // text-transform: capitalize;
    }
  }

  .form-control {
    background: #e8f0fe;
    border-color: #00000000;

    &:focus {
      color: #212529;
      background-color: #e8f0fe;
      border-color: #00000000;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
    }
  }
  select {
    font-size: 13px;
    color: #6e7191;
  }
  .registration-form-submit-button {
    button {
      border: none;
      background: #5756d8;
      width: 100%;
      border-radius: 5px;
      padding: 10px 30px;
      color: #fff;
      font-size: 16px;
    }
  }
}

.lafarge-reg-form-popup-wrap {
  .form-group {
    position: relative;

    &.phone-number-label-wrap {
      input {
        padding-left: 50px;
      }
      label.phone-number-label {
        position: absolute;
        top: 15px;
        left: 10px;
      }
    }
  }
}
.course-details-content-main-area h2 {
  margin-bottom: 25px;
}

// ======================

.course-more-btn-area-see-more-btn {
  display: flex;
  gap: 15px;
  > a > button {
    border: 2px solid #f7b941;
    background: #f7b941;
    color: #fff;
    &:hover {
      background: #fff;
      color: #f7b941;
    }
  }
  > button {
    border: 2px solid #5756d8 !important;
    color: #5756d8 !important;
    background: #fff !important;
    &:hover {
      background: #5756d8 !important;
      color: #fff !important;
      a {
        color: #fff !important;
      }
    }
  }
}
