.feature-single-item {
  text-align: center;
}

.feature-single-item img {
  height: 45px;
  filter: grayscale(1);
  display: inline-block;
}
.feature-single-item img:hover {
  filter: grayscale(0);
}
.feature-items-wrap {
  position: relative;
}

.slick-slider.slider-wrap.slick-initialized {
  width: 80%;
  margin-left: auto;
}

.our-customer h6 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #00000090;
  margin-bottom: 5px;
}

.our-customer h4 {
  font-size: 24px;
  font-weight: 700;
}
.our-customer {
  height: 100%;
  /* background: #fff; */
  position: absolute;
  z-index: 99;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  padding-left: 0;
  width: 20%;
  background: #fefdff;
}

@media only screen and (max-width: 770px) {
  .feature-single-item img {
    width: unset;
    /* height: unset; */
  }
  .footer-single-item-content.insights-image img {
    height: 50px;
  }

  .teacher-single-item.text-center img {
    height: 260px;
  }
  .slick-slider.slider-wrap.slick-initialized {
    width: unset;
  }
  .our-customer {
    width: 160px;
  }

  .our-customer h6 {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #00000090;
  }

  .our-customer h4 {
    font-size: 13px;
  }
  .our-customer {
    height: 100%;
    background: #fff;
    position: unset;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    padding-left: 0;
    text-align: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 30px;
  }
}
.feature-inner-area {
  overflow: hidden;
}
.feature-inner-area button.slick-arrow.slick-prev {
  display: none !important;
}
.feature-inner-area button.slick-arrow.slick-next {
  display: none !important;
}
* {
  margin: 0;
}

body {
  /* font-family: "Raleway", sans-serif; */
  font-family: "Sora", sans-serif;
}

p {
  color: #232536;
}

.text-center {
  text-align: center;
}

.App {
  overflow: hidden;
}

.slick-track {
  display: flex;
  align-items: center;
}

.call-hero-button-area a:nth-child(2) {
  background: unset;
  border: 1px solid #fff;
  color: #fff;
  margin-right: 0px;
}

.navbar__link.header-login-btn {
  color: #fff;
  background: #5756d8;
  border-radius: 6px;
  padding: 4px 25px;
  border: 2px solid transparent;
  transition: 0.3s;
  margin-left: 10px;
  display: inline-block;
}

a.navbar__link.header-login-btn:hover {
  background: #fff;
  border: 2px solid #5756d8;
  color: #5756d8;
}

a.navbar__link.header-registration-btn {
  border: 2px solid #5756d8;
  padding: 4px 25px;
  margin-left: 12px;
  display: inline-block;
  border-radius: 6px;
  color: #5756d8;
}

a.navbar__link.header-registration-btn:hover {
  background: #5756d8;
  color: #fff;
  transition: 0.5s;
  border-color: #5756d8;
}

.App.home-page-main-bg {
  background: #fafcff;
}
.teacher-single-item p {
  font-size: 18px;
}

/* ============================================================================ */
.business-clases-single-item-wrap {
  height: 350px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px #dddddd80;
  transition: 0.5s;
  position: relative;
}
.business-clases-main-part {
  height: 100%;
}
.business-clases-single-item-wrap:after {
  background: #00000050;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}
.business-clases-main-part-top {
  background: #00000033;
  text-align: center;
  padding: 10px 0;
  transition: 0.5s;
  position: relative;
  z-index: 9;
}

.business-clases-main-part-top h3 {
  color: #fff !important;
  font-size: 20px;
  padding: 6px 0;
}

.business-clases-main-part-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 20px 25px;
  /* padding-bottom: 30px; */
  transition: 0.5s;
  z-index: 9;
}
.business-clases-main-part-bottom-inner-left h6 {
  margin-bottom: 5px;
  font-size: 14px;
}
.business-clases-main-part-bottom-inner {
  display: flex;
  align-items: center;
  gap: 15px;
}
.business-clases-main-part-bottom i {
  font-size: 13px;
  padding: 5px 0;
}
.business-clases-main-part-bottom h4 {
  font-size: 18px;
  font-weight: 700;
  padding: 5px 0;
}
.business-clases-main-part-bottom-inner-left > h6 > span {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  margin-right: 5px;
}
.business-clases-main-part-top i {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  display: block;
}
/* .business-clases-single-item-wrap:hover {
  transition: 5s;
  transform: scale(1.1);
} */

/* ===================  */

.business-clases-single-item-wrap:hover .business-clases-main-part-top {
  transform: scale(0);
  transform-origin: top;
  transition: 0.5s;
}
.business-clases-single-item-wrap:hover .business-clases-main-part-bottom {
  transform: scale(0);
  transform-origin: bottom;
  transition: 0.5s;
}

.business-cases-service-area > * > .col-lg-4 {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 0;
  padding-bottom: 25px;
}

.business-clases-hover-part {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #292d321a;
  top: 0;
  z-index: 99;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 25px;
  transform: scale(0);
  transform-origin: center;
  transition: 0.5s;
  transition-delay: 0.1s;
}
.business-clases-single-item-wrap:hover .business-clases-hover-part {
  transform: scale(1);
  transition: 0.5s;
  transition-delay: 0.1s;
}
.business-clases-hover-part > * > p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
}
.business-clases-hover-part-bottom-link {
  position: absolute;
  width: 100%;
  background: #f7b941;
  left: 0;
  bottom: 0;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.business-clases-hover-part-bottom-link > a {
  text-decoration: none;
  color: #fff;
  display: flex;
  gap: 10px;
  font-weight: 500;
  font-size: 20px;
}
.business-clases-hover-part-bottom-link > a span:first-child {
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  top: 2px;
}
.business-cases-bottom-button-area {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.business-cases-bottom-button-area a {
  display: inline-block;
  color: #f7b941 !important;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 10px;
  border: 2px solid #f7b941;
  transition: 0.5s;
  font-weight: 600;
}
.business-cases-bottom-button-area.-service-area-page {
  justify-content: left;
}

.business-cases-bottom-button-area a:hover {
  background: #f7b941;
  color: #fff !important;
  transition: 0.5s;
}
/* ============================================================================ */
.footer-single-item-content.insights-image > a > img {
  height: 55px;
}
