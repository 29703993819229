.return-policy-main-wrap {
  margin: 60px 0;
  .return-policy-inner-content-wrap {
    background: #ffffff;
    box-shadow: 0px 10px 40px rgb(168 168 168 / 20%);
    border-radius: 20px;
    padding: 60px 140px;
    .return-policy-header {
      text-align: center;
      margin-bottom: 40px;
      h3 {
        color: #5756d8 !important;
        margin-bottom: 8px;
        font-size: 44px;
      }
      p {
        color: #23253660;
      }
    }
    .return-policy-single-item {
      margin-bottom: 40px;
      h4 {
        font-size: 24px;
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 26px;
        color: #232536 !important;
      }
    }
  }
}
