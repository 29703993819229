span.navbar-card-area-wrap {
  display: block;
  position: relative;
  width: 60px;
  text-align: center;
  border-left: 2px solid #dbe9fd;
  border-right: 2px solid #dbe9fd;
}
span.navbar-card-area-wrap span {
  width: 15px;
  height: 15px;
  position: absolute;
  background: #e5e5f3;
  line-height: 14px;
  border-radius: 50%;
  color: #5756d8;
  font-weight: 700;
  right: 12px;
  top: 0;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar__link.header-login-btn.ghbfjnkdlfjhbnvmjnv a.dropdown-item {
  border: none;
}

.login-after-navbar .navbar__link.header-login-btn .dropdown-menu.show {
  text-align: left;
}
