.cart-page-single-data-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0px;
}
table.cart-table-main-wrap-hgjfkdlujfnc.table {
  vertical-align: middle;
}
td.cart-page-first-item-tgbnhyujm {
  text-align: center;
}
.cart-page-single-data-item img {
  width: 100px;
}
table.cart-table-main-wrap-hgjfkdlujfnc.table tbody {
  border-top: 0;
  tr {
    border-bottom: 1px solid #ddd !important;
  }
}
table.cart-table-main-wrap-hgjfkdlujfnc.table.table-borderless thead {
  background: #eff2f7;
  tr {
    th {
      padding: 15px 0.5rem;
      font-size: 16px;
    }
  }
}
.cart-main-area-wrap-cart-body {
  border: 1px solid #ddd;
  margin: 20px 0;
}

.total-price-area {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  text-align: right;
}

.buy-courses-in-cart-page-main-process {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding: 20px;
}

.buy-courses-in-cart-page-main-process > * {
  border: none;
  padding: 8px 30px;
}

.buy-courses-in-cart-page-main-process > *:first-child {
  border: 1px solid #5756d8;
  background: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
}
.buy-courses-in-cart-page-main-process > *:last-child {
  border: 1px solid #5756d8;
  background: #5756d8;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.course-count-main-increement-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
  p {
    font-size: 16px;
  }
  span {
    font-size: 14px;
    background: #5756d8;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    font-weight: 400;
  }
}
.leretd-all-course-bottom-wrap {
  margin: 40px 0;
}

.leretd-all-course-bottom-wrap h4 {
  font-weight: 700;
  font-size: 26px;
}
.cart-page-inner-main-wrap {
  padding: 30px 0;
}
.cart-page-single-data-item h4 {
  color: #777;
  font-size: 16px;
  margin-bottom: 4px;
}
.cart-page-single-data-item > * > p {
  color: #77838f;
}
