.profile-image-upload-btn-gfhdjksuej > input {
  display: none;
}
.profile-image-upload-btn-gfhdjksuej {
  position: relative;
  width: 150px;
  margin: 25px auto;
  margin-bottom: 0px;
  text-align: center;
}
.profile-image-upload-btn-gfhdjksuej > {
  label {
    cursor: pointer;
    > img {
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #fff;
      right: 30px;
      top: 0;
    }
  }
}
.stident-profile-content-fgbhdnjmk.gfhdjskuedj {
  text-align: left;
}
.stident-profile-content-fgbhdnjmk.gfhdjskuedj > h3 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 20px;
}
.student-profile-image-area-top-tgbnmkiuh {
  // border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
::placeholder {
  font-size: 12px;
}
.student-profile-update-tgbdhsjka > button {
  display: block;
  width: 100%;
  background: #5756d8;
  border-color: #5756d8;
}
.student-profile-update-tgbdhsjka input {
  border-color: #e0e0e0;
  background: #f8fbff;
}
.student-course-profile-bottom-edfswrkjhg > h4 {
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 40px;
  font-size: 20px;
  color: #292d32;
}
.student-course-profile-bottom-edfswrkjhg.hjkrhdncbvcnmxjc h4 {
  margin-top: 0;
}
.course-sidebar-single-item-right-sdfghcjv > div > p:first-child {
  color: #b0aec9;
  font-size: 12px;
}
.course-sidebar-single-item-right-sdfghcjv > div > p:last-child {
  font-weight: 500;
  font-size: 14px;
}
