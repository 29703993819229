.navbar-toggle-button-right-area {
  position: absolute;
  right: 0;
  top: -15px;
  z-index: 9;
  display: none;
}
button.navbar-button-bar-icon {
  border: none;
  background: none;
  position: relative;
  top: 7px;
  span {
    display: block;
    width: 30px;
    height: 2px;
    margin-bottom: 6px;
    background: #000;
    transition: all 0.3s ease-in-out;
  }
}
button.navbar-button-close-icon {
  border: none;
  background: none;
  position: relative;
  span {
    display: block;
    width: 30px;
    height: 2px;
    margin-bottom: 6px;
    background: #000;
    position: absolute;
    &:nth-child(1) {
      transform: rotate(45deg);
      position: absolute;
      right: 0;
      top: 0;

      transition: all 0.3s ease-in-out;
    }
    &:nth-child(2) {
      transform: rotate(-45deg);
      position: absolute;
      right: 0;
      top: 0;
      transition: all 0.3s ease-in-out;
    }
  }
}

@media (max-width: 992px) {
  .flex-column.tab-content-part-part-wrap.lafarge-left-tab.nav.nav-pills {
    margin-bottom: 30px;
  }
}
@media (max-width: 768px) {
  .lafarge-training-inner-wrap {
    padding: 30px 15px;
    .lafarge-training-header-title {
      margin-bottom: 0px;
    }
  }
  .lafarge-landing-header-logo {
    img {
      height: 40px;
    }
  }
  .lafarge-landing-header-right > button {
    padding: 7px 15px;
  }
  .lafarge-hero-main-wrap {
    h3 {
      font-size: 30px;
    }
    p {
      font-size: 13px;
      line-height: 22px;

      letter-spacing: 0.5px;
      padding: 0px 0px;
    }
  }
  .desktop-show-navbar-area {
    display: none;
  }
  .lafarge-course-ware-header {
    position: relative;
    .navbar-toggle-button-right-area {
      display: block;
    }
    .mobile-show-navbar-area {
      position: absolute;
      right: 0;
      top: 0;
      ul {
        display: flex;
        justify-content: space-between;
        flex-direction: column !important;
        width: 200px;
        text-align: left;
        align-items: baseline;
        top: 38px;
        position: relative;
        background: #fff;
        padding: 20px;
        padding-top: 0;
      }
    }
  }
  .lafarge-training-inner-wrap .lafarge-training-items-wrap {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    padding: 40px 0;
    flex-wrap: wrap;
  }
  .lafarge-training-inner-wrap
    .lafarge-training-items-wrap
    .lafarge-training-item {
    width: 100%;
    margin-bottom: 15px;
  }
  .react-responsive-modal-modal.lafarge-training-modal {
    max-width: unset;
    width: 330px;
    padding: 20px;
    padding-top: 40px;
    position: relative;
    z-index: 1;
    min-height: unset;
  }
  .why-do-we-need-bottom-image {
    width: 100%;
    position: relative;
    min-height: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
  }

  .lafarge-video-area-wrap iframe {
    border-radius: 10px;
    min-height: 250px;
    overflow: hidden;
    width: 100%;
  }
  .lafarge-training-header-title h3 {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 700;
  }
  .negotiation-mrthod-content-area p {
    font-size: 14px !important;
    line-height: 24px;
  }
  .tab-content-right-single-tabs h5 {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .negotiation-mrthod-content-area > h4 {
    font-size: 24px;
    line-height: 36px;
  }
  .lafarge-exercise-ques-main-wrap
    .lafarge-exercise-single-item
    .lafarge-exercise-single-item-inner
    .lafarge-exercise-single-item-inner-left {
    flex-direction: column;
    text-align: center;
  }
  .lafarge-exercise-single-item-inner {
    padding: 10px 15px !important;
  }
  .lafarge-exercise-single-item p {
    margin-bottom: 10px;
    font-size: 14px !important;
  }
  .as-organization-content-area h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .lafarge-exercise-single-item-inner-right.preview-part {
    margin-top: 25px;
  }
  .lafarge-exercise-ques-main-wrap
    .lafarge-exercise-single-item
    .lafarge-ques-submit-button
    button.lafarge-ques-submit-button-inner {
    border: 2px solid #5756d8;
    color: #5756d8;
    padding: 10px 50px;
    background: #fff;
    font-weight: 600;
    font-size: 18px;
    border-radius: 10px;
    display: inline-block;
    margin-top: 30px;
  }
  .success-main-page-area-wrap {
    background: #fff;
    margin-top: 30px;
    padding: 70px 15px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .success-main-page-area-wrap .success-main-page-area img {
    display: inline-block;
    margin-bottom: 30px;
    height: 130px;
  }
  .success-main-page-area-wrap .success-main-page-area h3 {
    margin-bottom: 25px;
    color: #6a7187 !important;
    font-size: 34px;
    font-weight: 700;
    line-height: 44px;
  }
  .lafarge-course-ware-header ul li a.active-lafarge-link::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #f7b941;
    bottom: -5px;
    left: 0;
  }
}
@media (max-width: 992px) {
  .why-do-we-need-bottom-image {
    width: 100%;
    position: relative;
    min-height: 340px;
    display: flex;
    align-items: center;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
  }
}
