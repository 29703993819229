.student-profile-view-main-area {
  margin: 40px 0;
  .student-profile-title-area {
    h3 {
      font-weight: 700;
      font-size: 28px;
    }
  }
}
.course-sidebar-single-item.ujhgfbsdvzbsd > *:last-child {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.stident-profile-content-fgbhdnjmk {
  text-align: center;
  background: #fff;
  box-shadow: 0px 10px 20px rgb(182 182 182 / 10%);
  margin: 30px 0;
  padding: 40px;
  position: relative;
  border-radius: 10px;
}
.student-course-profile-bottom-edfswr {
  width: 100%;
  margin: 20px auto;
}
.student-course-profile-bottom-edfswr.jikbdkghfijk {
  .student-course-info-wsxcde {
    .edit-profile-student-jfhbuyhweu {
      border-radius: 15px;
      border-color: #5756d8;
      color: #5756d8;
    }
    padding: 10px;
    border-radius: 7px;
    font-weight: 700;
    border: 1px solid #e2e3e3;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgb(182 182 182 / 10%);
    background: #fff;
    p {
      color: #74788d;
    }
    h2 {
      font-size: 22px;
    }
  }
}

.student-profile-image-area-top-tgbnmkiuh {
  img {
    width: 115px;
    height: 115px;
    margin-bottom: 15px;
    border-radius: 50%;
  }
  h4 {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 20px;
  }
}

.edit-profile-student-part {
  position: absolute;
  right: 40px;
  top: 30px;
  a {
    display: inline-block;
    background: #583bab;
    padding: 10px 30px;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
  }
}
.course-list-profile-page.student-courses-page {
  .dashboard-sidebar-single-item {
    padding: 15px 0;
    .course-sidebar-single-item.ujhgfbsdvzbsd {
      background: #fff;
      border: 1px solid #f3f3f3;
    }
  }
}
.student-buy-course-list {
  box-shadow: 0px 10px 20px rgb(182 182 182 / 10%);
  background: #fff;
  border-radius: 10px;
  padding: 25px;
}
.student-buy-course-list-title h4 {
  font-weight: 700;
  margin-bottom: 10px;

  padding-bottom: 15px;
}
.course-sidebar-single-item-main-wrap.ujhgfbsdvzbsd.kjhgfedxgfd {
  border-bottom: 1px solid #ced4da;
  &:last-child {
    border-bottom: none;
  }
}
.student-buy-course-list-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cecece;
}
.course-sidebar-single-item-left-sdfghcjv.easfv > p {
  font-weight: 700;
  font-size: 16px;
}

.course-sidebar-single-item-right-sdfghcjv.rthbgxh > p {
  font-weight: 700;
  color: #5756d8;
}

.student-result-filtering-area-hdmbfkbn > select {
  font-size: 14px;
  font-weight: 600;
  color: #1b3f73;
  background: #f8fbff;
  border-color: #1b3f73;
  border-radius: 10px;
}
.student-profile-image-area-top-tgbnmkiuh.teacher-ghjuyhgbuygb {
  border-bottom: none;
  width: 70%;
  margin: 30px auto;
}
.student-profile-view-main-area label {
  font-style: italic;
  color: #828282;
}
.student-profile-image-area-top-tgbnmkiuh {
  > input {
    display: none;
  }
}
label.student-profile-student-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
  display: block;
  span {
    position: absolute;
    width: 24px;
    height: 24px;
    background: #5756d8;
    font-style: normal;
    color: #fff;
    border-radius: 50%;
    right: 5px;
    bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    text-align: center;
  }
  img {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
}
.student-course-info-wsxcde.edit-profile-student-jfhbuyhweu {
  border-radius: 18px;
  padding: 10px 10px;
  border-color: #5756d8;
  .edit-profile-student-part- > a {
    color: #5756d8;
  }
}
.student-course-info-wsxcde.dbsn {
  padding: 10px;
  p {
    font-size: 12px;
    color: #74788d;
    margin-bottom: 4px;
  }
  h2 {
    font-size: 22px;
    margin-bottom: 0;
  }
}
.business-clases-main-part-bottom i {
  color: #8a8b95;
}
.student-profile-image-area-top-tgbnmkiuh.teacher-ghjuyhgbuygb.jvndkvnb {
  width: 100% !important;
}
.stident-profile-content-fgbhdnjmk.mfhsdkjhb {
  padding: 20px 20px;
}
