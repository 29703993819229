.website-information-write-main-area {
  padding: 30px;
  background-color: #fff;
  border-radius: 0.2rem;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
  // height: 75vh;
  position: relative;
  margin-top: 50px;
  &::before {
    content: "";
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #eee;
  }
  .react-tabs {
    ul {
      padding-left: 0;
      li {
        list-style: none;
        padding: 8px 30px;
        font-size: 18px;
        font-weight: 500;
        display: block;
        cursor: pointer;
        &.react-tabs__tab.react-tabs__tab--selected {
          background: #556ee6;
          color: #fff;
          border-radius: 7px;
          & h2 {
            margin-bottom: 30px;
          }
        }
      }
    }
    .react-tabs__tab-panel.react-tabs__tab-panel--selected {
      .ql-container.ql-snow {
        border: 1px solid transparent;
      }
      .editor-website-info-wrap {
        border: 1px solid #ccc;
      }
      .website-info-data-bottom {
        padding-top: 30px;
        text-align: center;
        label {
          margin-bottom: 25px;
          display: block;
          border: 1px solid #ccc;
          padding: 10px 14px;
          border-radius: 5px;
          font-size: 15px;
          text-align-last: left;
        }
        button {
          border: 1px solid #ccc;
          padding: 10px 24px;
          background: #556ee6;
          color: #fff;
          border-radius: 7px;
          font-size: 15px;
        }
      }
    }
  }
}

.tabs-left-side-main-wrap-settings-page {
  position: relative;
  padding-right: 50px;
  height: 70vh;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background: #eee;
  }
}
