// =====================================
.dashboard-topbar-logo {
  width: 250px;
  text-align: center;
  img {
    height: 100%;
  }
}
button.main-sidebar-toggle-button {
  border: none;
  font-size: 24px;
  padding-right: 0;
  background: #5756d8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  text-align: center;
  width: 30px;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  right: -14px;
  top: 100px;
}
label#EZDrawer__overlay {
  display: none !important;
}
.dashboard-topbar-main-wrap {
  position: fixed;
  width: 100%;
  z-index: 99999;
  background: #ffffff;
  box-shadow: 0 0 10px 0px rgb(0 0 0 / 4%);
  height: 80px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
}
.dashboard-main-body {
  padding-left: 280px;
  padding-top: 100px;
  width: 100%;
  height: 100vh;
  background: #fafafa;
  transition: 0.5s;
  padding-right: 50px;
}
.dashboard-sidebar-nav-area {
  width: 250px;
  position: absolute;
  height: 100vh;
  z-index: 9999;
}
.navbar-inner-main-wrap-items-wrap {
  padding-top: 100px;
  //   background: #f9fafb !important;
}
.dashboard-sidebar-nav-area {
  width: 0px;
  position: absolute;
  height: 100vh;
  z-index: 9999;
  padding-top: 100px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px #00000010;
}
.navbar-item-single-main-area-jddiu ul {
  padding-left: 0;
}

.navbar-item-single-main-area-jddiu ul li {
  list-style: none;
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
  color: #573b81;
  width: 100%;
  font-size: 13px;
  position: relative;
  // height: 40px;
  cursor: pointer;
  img {
    filter: grayscale(1);
    height: 18px;
  }
}

.navbar-item-single-main-area-jddiu ul li a {
  list-style: none;
  display: flex;
  padding: 10px 10px;
  gap: 10px;
  text-decoration: none;
  color: #573b81;
  margin: 0 10px;
  border-radius: 10px;
  font-size: 13px;
  i {
    position: relative;
    font-size: 20px;
  }
  img {
    filter: grayscale(0.6);
  }
}
.navbar-item-single-main-area-jddiu ul li > span {
  list-style: none;
  display: flex;
  padding: 10px 10px;
  gap: 10px;
  text-decoration: none;
  color: #573b81;

  margin: 0px 10px;
}
a.admin-navlink {
  width: 100%;
  background: #f5f5fd;
  padding: 10px 10px;
  color: #5756d8 !important;
}
a.admin-navlink span {
  color: #5756d8;
  img {
    filter: grayscale(0) !important;
  }
}
.dashboard-sidebar-nav-area.showNavbar {
  width: 50px;
  transition: 1s;
}
.dashboard-main-body.showNavbar {
  padding-left: 100px;
}
#EZDrawer #EZDrawer__container {
  position: fixed;
  box-shadow: 0 0 10px 0px rgb(0 0 0 / 4%) !important;
}
.dashboard-topbar-items-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboard-main-area-single-content {
  display: flex;
  gap: 15px;
  background: #fff;
  padding: 25px 10px;
  border-radius: 10px;
  margin-bottom: 25px;
  padding-left: 25px;
  box-shadow: 0px 10px 20px rgb(182 182 182 / 10%);
  border: 1px solid #f3f3f3;
  padding-bottom: 20px;
  span {
    p {
      color: #77838f;
    }
  }
}
.dashboard-main-area-single-content > span:first-child {
  width: 45px;
  height: 45px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: peru;
  color: #fff;
  border-radius: 50%;
}

.dashboard-main-area-single-content h6 {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-top: 4px;
  // font-family: "Lato", sans-serif;
}
.dashboards-title {
  margin-bottom: 20px;
  margin-top: 30px;
}

.dashboards-title h4 {
  font-weight: 700;
}
.dashboard-sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-sidebar-single-item {
  background: #fff;
  padding: 15px 30px;
  border-radius: 10px;
  margin-bottom: 25px;
  box-shadow: 0px 10px 20px rgb(182 182 182 / 10%);
  border: 1px solid #f3f3f3;
}
.course-sidebar-single-item {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.course-sidebar-single-item-right p {
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
}
aside.dashboard-sidebar-right {
  padding-top: 40px;
}
.course-category-single-item {
  width: 100%;
  height: 100px;
  background: #e5fae9;
  text-align: center;
  border-radius: 10px;
}

// .course-category-single-item h4 {
//   font-size: 14px;
//   font-weight: 700;
//   padding: 10px;
// }
.dashboard-sidebar-title-left > h5 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}
.course-category-single-item {
  &.first-course-item {
    background: #e5fae9;
  }
  &.second-course-item {
    background: #dcf7fe;
    span {
      i {
        color: #3bd0f7 !important;
      }
    }
  }
  &.third-course-item {
    background: #efeff7;
    span {
      i {
        color: #6464ca !important;
      }
    }
  }
  &.fourth-course-item {
    background: #e5fae9;
    span {
      i {
        color: #5ede77 !important;
      }
    }
  }
  &.five-course-item {
    background: #fdf3e9;
    span {
      i {
        color: #ec8319 !important;
      }
    }
  }
  &.six-course-item {
    background: #efeff7;
    span {
      i {
        color: #6464ca !important;
      }
    }
  }
  width: 100%;
  height: 100px;
  background: #e5fae9;
  text-align: center;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  span {
    display: inline-block;
    h4 {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    span {
      .bx-palette {
        color: #5ede77;
        font-size: 24px;
      }
    }
  }
}
.dropdown-coustom-design-class-fghdnjmk {
  background: none;
  color: #000 !important;
  border: none;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: unset;
  background-color: unset !important;
  border-color: unset;
}

.dashboard-right-side-profile-item {
  .btn-primary:hover {
    background-color: unset !important;
    border-color: unset;
    outline: none;
  }
  .btn-check:active + .btn-primary:focus,
  .btn-check:checked + .btn-primary:focus,
  .btn-primary.active:focus,
  .btn-primary:active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 0%);
    color: unset !important;
  }
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #573b81 !important;
}
.dashboard-right-side-profile-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.dashboard-main-profile-wrap-language select {
  border: none;
  background: none;
}
.dashboard-main-profile-wrap-language {
  width: 80px;
  background: #f3f3f3;
  height: 30px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding-left: 5px;
}
span.dashboard-topbar-language-globe-icon > i {
  color: #ec7323;
  font-size: 20px;
  position: relative;
  top: 1px;
  left: 5px;
}
.dashboard-main-profile-wrap-language select {
  border: none;
  background: none;
  padding: 0 10px;
}
.dashboard-main-profile-wrap-notifications > span > i {
  font-size: 20px;
}
// .form-select:focus {
//   border-color: unset;
//   outline: 0;
//   box-shadow: none;
// }
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  // color: #fff;
  background-color: unset;
  border-color: #0a58ca;
  box-shadow: none;
}
.dashboard-main-profile-wrap-admin .dropdown-menu {
  position: relative !important;
  // display: block !important;
  transform: scale(0) !important;
}
.dashboard-main-profile-wrap-admin .dropdown-menu {
  position: absolute !important;
  left: unset !important;
  right: 0 !important;
  top: 30px !important;
}
.dashboard-main-profile-wrap-admin .dropdown-menu.show {
  transform: scale(1) !important;
  top: 10px !important;
  border: none !important;
  padding: 0;
  position: absolute !important;
  left: unset !important;
  right: 0 !important;
  top: 50px !important;
  a {
    border: none !important;
  }
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.dropdown-toggle-admin-page {
  background: #fff;
  color: #000;
  padding: 0;
  border: none;
  font-size: 13px;
}
a.dropdown-item {
  padding: 10px 0;
  padding-left: 10px;
}
.btn-primary:hover {
  color: unset;
  background-color: unset;
  border-color: unset;
  // border: 1px solid transparent;
}
.jnjvlzkdmxcmnb.show.dropdown button.btn-primary:hover {
  color: unset;
  background-color: unset;
  border-color: unset;
  // border: none !important;
  border: 1px solid transparent;
}
.packge-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.packge-header-right > a {
  display: inline-block;
  background: #5756d8;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 10px;
}
.packge-header-right > button {
  display: inline-block;
  background: #5756d8;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 10px;
  border: none;
}
.packge-header-left p > span {
  color: #f7b941;
}
.title > h3 {
  margin-bottom: 10px;
}
.packge-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e6e6;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.package-control-action-area {
  display: flex;
  align-items: center;
  gap: 10px;
}

.package-control-action-area button {
  border: none;
  background: none;
}

.package-control-action-area button {
  border: 1px solid #a8b1b8;
}
td {
  color: gray;
}
.package-main-content-list {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgb(182 182 182 / 10%);
}
.package-main-content-list tbody tr:last-child {
  border-bottom: none !important;
}
thead.package-main-content-list-table-header {
  background: #f5f7f9;
}

thead.package-main-content-list-table-header tr th {
  padding: 15px;
}
.package-main-content-list tbody tr {
  border-bottom: 1px solid #dddddd69;
}
.package-main-content-list tbody tr td {
  // padding: 0px;
  i {
    color: #00cd2d;
    font-size: 22px;
  }
}
.package-control-action-area a {
  text-decoration: none;
  color: gray;
  padding: 4px 12px;
  border: 1px solid #77838f;
  border-radius: 4px;
  margin-top: 15px;
}
.new-individual-course {
  background: #fff;
  padding: 30px;
}

.form-submit-button-area-wrap {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
}

.form-submit-button-area-wrap button {
  border: none;
  background: none;
}

button.btn.create-new-item {
  background: #5756d8;
  color: #fff;
}

button.btn.save-to-new-item-draft {
  border: 1px solid #5756d8;
  color: #5756d8;
}
.new-individual-course-title {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 60px;
}
.dashboard-main-profile-wrap-admin button > img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.profile-top-view {
  display: flex;
  width: 100%;
  gap: 2vw;
  justify-content: space-between;
  align-items: center;
  .profile-image img {
    width: 150px;
  }
  .profile-content {
    width: 90%;
    tr {
      border-bottom: unset;
    }
  }
}
.user-info-single-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  .user-info-item {
    width: 150px;
  }
}
.user-info-item > span > img {
  position: relative;
  top: -1px;
}
.profile-general-information {
  background: #fff;
  margin-top: 25px;
  padding: 25px;
  height: 100%;
}
.profile-general-information > h4 {
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
}
.user-info-item2 > span {
  padding-left: 7px;
}
.payment-method-wrapper-sdfcgvhbjn {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 5px 10px;
}
.profile-user-info-wrap {
  margin-bottom: 15px;
}
.profile-user-info-wrap > p {
  font-size: 14px;
  margin-bottom: 5px;
}
.payment-method-wrapper-sdfcgvhbjn p {
  font-size: 13px;
  padding: 5px;
}
.payment-method-wrapper-sdfcgvhbjnhgvbj > ul {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 0;
  margin-top: 15px;
}

.payment-method-wrapper-sdfcgvhbjnhgvbj > ul > li {
  border: 1px solid #ddd;
  padding: 5px 20px;
  border-radius: 25px;
  list-style: none;
  font-size: 13px;
}

.course-sidebar-single-item.ujhgfbsdvzbsd {
  width: 100%;
  background: #f5f7f9;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  border-radius: 5px;
}

.course-sidebar-single-item-left-sdfghcjv {
  display: flex;
  align-items: center;
  gap: 15px;
}

.course-sidebar-single-item-right-sdfghcjv {
  display: flex;
  align-items: center;
  gap: 30px;
}

.course-sidebar-single-item-right-sdfghcjv > button {
  border: 1px solid #5756d8;
  padding: 10px 30px;
  background: #fff;
  border-radius: 5px;
  color: #5756d8;
}

.course-sidebar-single-item-right-sdfghcjv > span {
  background: #10cb0c;
  color: #ffff;
  padding: 5px 20px;
  border-radius: 25px;
}
.course-sidebar-single-item-right-sdfghcjv.rthbgxh button a {
  text-decoration: none;
  color: #ec7323;
}
.course-sidebar-single-item-right-sdfghcjv.rthbgxh button {
  border-color: #ec7323;
}
.course-sidebar-single-item-left-sdfghcjv.easfv > img {
  width: 40px;
  height: 40px;
}
.course-sidebar-single-item.ujhgfbsdvzbsd.kjhgfedxgfd {
  background: #fff;
  padding: 15px 0;
}
.jnjvlzkdmxcmnb.show.dropdown > * > a {
  padding: 10px 0 !important;
  gap: 5px !important;
  align-items: center !important;
}
.button-area-input-data-wrap > input {
  display: none;
}
.button-area-input-data-wrap label {
  padding: 10px 30px;
  background-color: #f3f8ff;
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  border-radius: 10px;
  font-weight: 700;
  display: block;
  text-align: center;
  cursor: pointer;
  border: 1px dashed gray;
  overflow: hidden;
}
.add-new-format-area-wrap {
  width: 500px;
  margin: auto;
  text-align: center;
  position: relative;
}
.delete-btn-rfvbjuyghbjuyhnm {
  position: absolute;
  right: 20px;
  top: 20px;
  button {
    background: rgb(255 0 0 / 15%);
    border: none;
    width: 40px;
    height: 40px;
    font-size: 20px;
    border-radius: 50%;
    color: red;
    i {
      position: relative;
      top: 2px;
    }
  }
}
.package-main-content-list.kjhgfwertyuiutfc {
  background: unset;
}
.add-new-format-inner-item-wrap {
  background: #fff;
  padding: 40px 30px;
}

form.format-upload-form-main-area-zkjdh {
  text-align: left;
  margin-top: 40px;
}

.create-new-format-btn-area-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin: 15px 0;
  margin-top: 40px;
}

.create-new-format-btn-area-wrap button {
  background: #5756d8;
  border: 1px solid #5756d8;
  color: #fff;
  padding: 10px 25px;
  border-radius: 10px;
}
.create-new-format-btn-area-wrap button:last-child {
  background: #fff;
  color: #5756d8;
}
.button-area-input-data-wrap > label {
  position: relative;
}
.button-area-input-data-wrap > label > img {
  position: absolute;
  height: 34px;
  left: 20px;
  top: 3px;
}
// form.format-upload-form-main-area-zkjdh input {
//   height: 45px;
// }
.data-search-inner-area-jhhbcvhkjzjghfjdn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}
.search-box-dfghjkiujfb input {
  padding: 6px 25px;
  border: 1px solid #ced4da;
  border-radius: 25px;
}
.data-search-and-filtering-area-wrap {
  padding: 30px 30px;
  margin: 30px 0;
  margin-bottom: 0;
  background: #fff;
  border-radius: 5px;
}
.package-main-content-list.new-main-area {
  padding: 50px 70px;
  position: relative;
}
.input-data-single-field > button {
  display: block;
  width: 90%;
  font-size: 24px;
  border: 1px solid #ddd;
  background: none;
  margin-left: auto;
}
.profile-rangking-content {
  display: flex;
  gap: 20px;
  align-items: center;
}
.profile-rangking-content-right p {
  display: flex;
  align-items: center;
  gap: 6px;
}

.profile-rangking-content-right p span i {
  position: relative;
  top: 2px;
}
.course-rank-main-area {
  background: #fff;
  padding: 30px;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 10px 20px rgb(182 182 182 / 10%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.course-rank-title {
  margin-bottom: 40px;
}
table.ihdughghvjjeifck.table.table-borderless thead tr {
  background: #f5f7f9;
}
// .select-filtering-area-jyfgvasjyhdfb {
//   margin-bottom: 30px;
// }
.navbar-item-single-main-area-jddiu ul li a img {
  filter: grayscale(0.6);
  height: 18px;
}
.jnjvlzkdmxcmnb.show.dropdown .dropdown-menu.show {
  position: absolute !important;
}
button.accordion-button {
  background: #fff !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 0%);
  border-color: unset !important;
  border: none !important;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}
.accordion-item {
  background-color: #fff;
  border: none;
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 0%);
  span {
    font-size: 13px;
  }
}
button.accordion-button {
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    font-size: 13px;
  }
}
.accordion {
  width: 100%;
}
.input-data-single-field.mb-3 > div > div {
  div {
    padding: 0px 5px;
    div {
      margin: 0;
      padding: 0;
    }
  }
}
.dashboard-dropdown-menuarea {
  position: relative;
  padding: 10px 20px;
  padding-left: 20px;
}

.dashboard-dropdown-menuarea > div {
  position: absolute;
  right: 0;
  top: 10px;
}
ul.active_subMenu {
  position: relative;
  transform: scaleY(0);
  height: 0;
  // transition: 0.4s;
}
ul.sub-menu {
  padding-top: 20px;
}
.dashboard-dropdown-menuarea > span > span img {
  padding-right: 10px;
}
.dropdown-item.active,
.dropdown-item:active {
  color: unset;
  text-decoration: none;
  background-color: unset !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: unset !important;
  background-color: unset !important;
}

.sales-analyticx-wrap {
  background: #fff;
  padding: 25px;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgb(182 182 182 / 10%);
  border-radius: 8px;
}
.bx-menu:before {
  content: "\ea50" !important;
}

.dashboard-sidebar-nav-area.showNavbar
  .navbar-item-single-main-area-jddiu
  ul
  li
  a
  span {
  position: absolute;
  right: 0;
}
.dashboard-sidebar-nav-area.showNavbar
  .navbar-item-single-main-area-jddiu
  ul
  li
  a
  span:last-child {
  right: -215px;
  text-align: left;
  width: 200px;
  display: none;
}
.dashboard-sidebar-nav-area.showNavbar
  .navbar-item-single-main-area-jddiu
  ul
  li
  a
  span:first-child {
  padding-right: 17px;
}
.dashboard-sidebar-nav-area.showNavbar
  .navbar-item-single-main-area-jddiu
  ul
  li
  a.admin-navlink {
  background: none !important;
}
.dashboard-sidebar-nav-area.showNavbar
  .navbar-item-single-main-area-jddiu
  ul
  li
  .dashboard-dropdown-menuarea {
  padding-left: 20px;
  // position: absolute;
  .icon_reverse {
    display: none;
  }
}

.dashboard-main-area-single-content {
  &.color-1 > {
    span:first-child {
      background: #5756d8;
    }
  }
  &.color-2 > {
    span:first-child {
      background: #f7b941;
    }
  }
  &.color-3 > {
    span:first-child {
      background: #689f39;
    }
  }
  &.color-4 > {
    span:first-child {
      background: #ea80fc;
    }
  }
  &.color-5 > {
    span:first-child {
      background: #00acc2;
    }
  }
  &.color-6 > {
    span:first-child {
      background: #01c853;
    }
  }
}
.title > p {
  display: flex;
  align-items: center;
}
.package-main-content-list > table > * > tr > *:first-child {
  padding-left: 25px;
}
.dashboard-sidebar-nav-area.showNavbar
  .navbar-item-single-main-area-jddiu
  ul
  li
  .dashboard-dropdown-menuarea {
  & > *:first-child {
    display: none;
  }
  & > *:nth-child(2) {
    display: block;
    span:first-child {
      position: relative;
    }
    span:last-child {
      display: none;
    }
  }
  & > *:last-child {
    position: absolute;
    right: -136px;
    top: 0;
  }
  width: 100%;
  height: 50px;
  padding-left: 20px;
  right: 0;
  text-align: right;
  padding: 7px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.dashboard-main-profile-wrap-admin .dropdown-menu.show > a {
  padding: 0;
  span.dropdown-item {
    padding-left: 0;
  }
  a {
    padding: 0;
    margin: 0;
    padding-left: 15px;
  }
}
.navbar-item-single-main-area-jddiu ul li:nth-child(4) {
  padding: 0 !important;
}
.dashboard-sidebar-nav-area.showNavbar
  .navbar-item-single-main-area-jddiu
  ul
  li {
  min-height: 40px;
}
.dashboard-sidebar-nav-area.showNavbar
  .navbar-item-single-main-area-jddiu
  ul
  li:hover
  a
  span:last-child {
  display: block;
}
.commin-soon-pages {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  img {
    height: 65%;
  }
}
.package-main-content-list table {
  vertical-align: middle;
}

//lafarge
.search-box {
  .form-control {
    border-radius: 30px;
    padding: 5px 10px 10px 40px;
  }
  .search-icon {
    font-size: 16px;
    position: absolute;
    left: 13px;
    top: 2px;
    line-height: 38px;
  }
}
.select-area {
  width: 23%;
}
.header-card {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
  border-radius: 3.25px;
  padding: 22px 44px;
  text-align: center;
  span {
    color: #94a3b8;
    font-size: 13px;
  }
}
.modal-backdrop {
  z-index: 9999999 !important;
}
.student_modal {
  z-index: 9999999 !important;
  .modal-xl {
    max-width: 1650px;
  }

  .modal-body {
    padding: 25px 25px 35px 25px;
  }
  .modal-header {
    border-bottom: unset;
  }
  .btn-close {
    border: 1px solid;
    border-radius: 50%;
  }
  .modal-content {
    padding: 16px;
    border-radius: 10px;
  }
  .embed-responsive-16by9 iframe {
    width: 100%;
    border-radius: 10px;
    height: 348px;
  }
}
.game_card {
  background: #ffffff;
  /* Shadow Cart */
  width: 100%;
  box-shadow: 0px 10px 30px rgba(138, 139, 149, 0.1);
  border-radius: 10px 10px 10px 10px;
  .game_image {
    img {
      width: 100%;
      height: 165px;
      /* position: absolute; */
      object-fit: cover;
      position: relative;
      /* top: -21px; */
      /* left: -27px; */
      border-radius: 10px 10px 0px 0px;
    }
  }
  .header-content {
    position: relative;
    top: -143px;
    padding-left: 22px;
    p {
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
    }
    span {
      font-size: 9px;
      color: #ffffff;
    }
  }

  .content_area {
    position: relative;
    padding-left: 22px;
    top: -26px;
    span {
      color: #8a8b95;
    }
    h6 {
      margin-bottom: 13px;
    }
  }
}
.paginationContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ul {
    text-align: center;
    list-style: none;
    display: flex;
    li.prevNext {
      background-color: transparent;
      i {
        font-size: 18px;
      }
    }
    li {
      padding: 4px 12px;
      background-color: #eff2f7;
      border-radius: 50%;
      margin: 0 3px;
      display: flex;
      align-items: center;
      cursor: pointer;
      button {
        padding: none;
        border: none;
        background-color: transparent;
      }
    }
  }
  .activePage {
    background-color: #0b2e4e;
    color: #fff;
  }
}
// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;
    line-height: 32px;
  }
}
