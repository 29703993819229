.academic-right-single-item {
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  padding: 14px 0;
}

.business-clases-main-part-bottom-inner-left img {
  height: 20px;
}
.hero-slider-content-right.category-page h6 {
  font-size: 18px;
  color: #583bab !important;
  font-weight: 700;
  display: block;
  padding-bottom: 10px;
  border-bottom: 1px solid #dfdbdb;
}
.category-inner-area-main-wrap .business-clases-hover-part > * > p {
  color: #fff;
  font-size: 12px;
  margin-bottom: 20px;
}
.category-inner-area-main-wrap .business-clases-hover-part-bottom-link > a {
  font-size: 16px;
}
.category-inner-area-main-wrap .business-clases-main-part-top h3 {
  color: #fff;
  font-size: 14px;
}
.category-inner-area-main-wrap .business-clases-main-part-bottom h4 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
}
.category-inner-area-main-wrap .business-clases-main-part-bottom-inner-left h6 {
  font-size: 14px;
}
.category-inner-area-main-wrap .business-clases-single-item-wrap {
  margin-bottom: 20px;
}
.search-area-input-field-and-button {
  width: 100%;
  height: 50px;
  display: flex;
  gap: 15px;
  margin-bottom: 25px;
  align-items: center;
  .search-area-input-field-box-wrap {
    width: 80%;
    height: 40px;
    overflow: hidden;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 10px;
    i {
      position: absolute;
      left: 15px;
      z-index: 99;
      font-size: 22px;
      top: 28%;
    }
    input {
      width: 100%;
      height: 100%;
      padding-left: 40px;
      border: none;
    }
  }
  button {
    width: 20%;
    height: 40px;
    border: 1px solid #ddd;
    background: #583bab;
    color: #fff;
    border-radius: 10px;
  }
}
.course-filtering-area-wrap-inner-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 5px;
  padding-top: 5px;
  span {
    display: flex;
    gap: 10px;
  }
}
.academic-right-single-item.category-page label {
  font-size: 11px;
  font-style: italic;
  color: #58595b;
}
.academic-right-area-wrap.category-page {
  height: unset !important;
}
.category-inner-area-main-wrap {
  padding: 40px 0;
}
.academic-right-single-item.category-page:last-child {
  border-bottom: none;
}
ul.tab-list-grid-course-data > li {
  border: none !important;
  padding: 0 !important;
}
table.list-course-view-data-table-main-area-wrap.table.table-borderless {
  thead {
    background: #eff2f7;
    tr {
      th {
        padding: 15px;
      }
    }
  }
  tbody > tr {
    border-bottom: 2px solid #f5f5f5;
    td {
      width: 19%;
      &:first-child {
        width: 30%;
      }
      &:last-child {
        width: 14%;
      }
      p {
        color: #555;
      }
      padding: 15px;
    }
  }
}
.react-tabs__tab-panel.react-tabs__tab-panel--selected .table > tbody {
  vertical-align: middle;
}
.list-course-view-data-table-jdhbbd {
  display: flex;
  align-items: center;
  gap: 10px;
  h4 {
    font-size: 14px;
  }
  img {
    height: 50px;
    border-radius: 5px;
  }
}
.list-course-view-data-table-jdhbbd > a {
  display: flex;
  gap: 5px;
  text-decoration: underline;
  color: #5756d8;
}
.react-tabs__tab.react-tabs__tab--selected > i {
  color: #5756d8;
}
.react-tabs__tab > img {
  filter: grayscale(1);
}
table.list-course-view-data-table-main-area-wrap.table.table-borderless {
  border: 1px solid #eff2f7;
  border-radius: 4px;
  overflow: hidden;
}
ul.tab-list-grid-course-data {
  li {
    &:first-child {
      font-size: 24px;
      line-height: 44px;
    }
    &:last-child {
      font-size: 30px;
    }

    > i {
      color: #ddd;
    }
  }
}
