.lafarge-exercise-ques-main-wrap {
  .form-group {
    margin: 1rem 0;
  }

  input[type="radio"] {
    display: none;
  }

  label {
    cursor: pointer;
    position: relative;
    font-size: 20px;
  }

  label::before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 2px solid #d9d9d9;
    border-radius: 50%;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
    transition: border-color 400ms ease;
  }

  label::after {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #017d3c;
    border: 2px solid #017d3c;
    border-radius: 50%;
    top: 50%;
    left: -26px;
    transform: translateY(-50%) scale(0);
    transition: transform 400ms ease;
  }

  input[type="radio"]:checked + label::before {
    border-color: #017d3c;
  }

  input[type="radio"]:checked + label::after {
    transform: translateY(-50%) scale(0.55);
  }

  .lafarge-exercise-single-item-inner-right.checkbox-data-field {
    .styled-checkbox {
      position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
      }

      // Box.
      & + label:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 30px;
        height: 30px;
        background: white;
        border-radius: 3px;
      }

      // Box hover
      &:hover + label:before {
        background: #e8e8e8;
      }

      // Box focus
      // &:focus + label:before {
      //   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      // }

      // Box checked
      &:checked + label:before {
        background: #017d3c;
        width: 30px;
        height: 30px;
        border-radius: 3px;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: "";
        position: absolute;
        left: -15px;
        top: 9px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
          4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
        border: none;
      }
    }

    // Demo-only styles
    // --------------

    ul.question-checkbox-list {
      padding-left: 30px;
      label {
        padding-left: 20px;
      }
    }

    li {
      margin: 20px 0;
    }

    // .centered {
    //   width: 300px;
    //   margin: auto;
    // }

    .title {
      text-align: center;
      color: rgb(69, 113, 236);
    }
  }
}
