.checkout-cart-items-wrap-rfdgbhghfgcxvbnb.hbnvkjcb {
  table {
    tbody {
      tr {
        // border: 1px solid #e5e5f0;
        padding: 10px 15px;
        border-radius: 10px;
        td {
          //   position: relative;
          .course-remove-delete-btn {
            position: absolute;
            right: 11px;
            top: 10px;
            i {
              font-size: 18px;
            }
          }
          h6 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 7px;
          }
        }
        td:first-child {
          width: 25%;
          img {
            width: 100%;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
.checkout-cart-items-wrap-rfdgbhghfgcxvbnb.hbnvkjcb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .ssl-commerce-require-pages-link {
    display: flex;
    gap: 20px;
  }
}
.cart-header-title-checkout-page {
  margin: 25px 0;
  h4 {
    font-weight: 700;
  }
}
.checkout-details-single-item-cvbn {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5f0;
  align-items: center;
}

// .checkout-details-single-item-cvbn:last-child {
//   border-bottom: none;
// }
.main-checkout-details-wrap {
  background: #fff;
  padding: 25px;
  border: 1px solid #e5e5f0;
  border-radius: 10px;
}
.checkout-coupon-area {
  border: 1px solid #e5e5f0;
  padding: 5px 20px;
  padding-right: 5px;
  border-radius: 10px;
  input {
    border: none;
    &:focus {
      outline: none;
    }
  }
  button {
    border: none;
    background: #5756d8;
    color: #fff;
    padding: 8px 25px;
    border-radius: 10px;
  }
}
.checkout-details-efvbhui.lkjhgfd {
  .checkout-coupon-area {
    &.confirm-checkout button {
      background: #5756d8;
    }
    padding: 0px 0px;
    button {
      background: #74788d;
    }
  }
}
// .checkout-coupon-area
.checkout-name-plmhtr.jhgfdefgb {
  display: flex;
  align-items: center;
  gap: 10px;
}
.checkout-content-checkout-page-main-wrap {
  box-shadow: 0px 10px 20px rgb(18 38 63 / 4%);
  background: #fff;
  padding: 30px;
  border-radius: 10px;
}
.checkout-cart-items-wrap-rfdgbhghfgcxvbnb.hbnvkjcb table tbody tr {
  padding: 7px 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  position: relative;
}
tr.checkout-ghdjkiuhybnjiduhbn {
  margin-bottom: 20px;
}
