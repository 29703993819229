.course-share-btn-area {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-bottom: 30px;
}
.course-share-btn-area a {
  display: flex;
  padding: 8px 20px;
  color: #bdbdbd;
  gap: 4px;
  align-items: center;
  text-decoration: none;
  border: 1px solid #bdbdbd;
  border-radius: 6px;
}
.course-details-content-main-area {
  h2 {
    margin-top: 20px;
    font-weight: 700;
    font-size: 48px;
  }
}
.details-page-inner-main-wrap > p {
  margin-top: 20px;
  margin-bottom: 0;
}

.course-details-single-box-item-wrap {
  background: #fff;
  box-shadow: 5px 10px 30px rgb(182 182 182 / 15%);
  border-radius: 10px;
  padding: 20px 25px;
  margin-bottom: 15px;
  i {
    display: inline-block;
    margin-bottom: 12px;
  }
  h4 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
  }
}
.course-more-btn-area-see-more-btn button {
  background: #f7b941;
  color: #fff;
  border: none;
  padding: 12px 25px;
  margin-bottom: 45px;
  border-radius: 5px;
  margin-top: 30px;
}
.content-decription-coursware {
  background: #fff;
  padding: 50px;
  box-shadow: 5px 10px 30px rgb(182 182 182 / 15%);
  margin-bottom: 30px;
  border-radius: 10px;
  p {
    font-size: 14px;
    font-weight: 400;
  }
  i {
    font-size: 18px;
    line-height: 31px;
    display: inline-block;
    margin-bottom: 10px;
  }
}

a.back-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #5756d8;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  span {
    i {
      font-size: 22px;
      position: relative;
      top: 3px;
    }
  }
}

.course-price-wrap-jdkbad {
  background: #fff;
  display: flex;
  align-items: center;
  gap: 20px;
  box-shadow: 5px 10px 30px rgba(182, 182, 182, 0.15);
  border: 1px solid #dfdbdb;
  padding: 10px 15px;
  border-radius: 10px;
}

.course-price-wrap-jdkbad h1 {
  color: #f7b941;
  font-weight: 700;
}
.course-price-wrap-jdkbad i {
  color: #8a8b95;
}
.buy-course-main-wrap {
  display: flex;
  text-align: center;
  width: 100%;
  background: #fff;
  border: 2px solid #5756d8;
  color: #5756d8;
  border-radius: 10px;
  margin-top: 30px;
  padding: 0px 5px;
}
.buy-course-main-wrap img {
  padding: 10px;
}
.buy-course-main-wrap button {
  border: none;
  background: none;
  text-align: center;
  width: 80%;
  font-size: 16px;
  font-weight: 600;
  color: #5756d8;
  &.btn-primary:focus {
    color: #5756d8 !important;
  }
}
.buy-course-main-wrap.fdgf button {
  width: 100%;
  padding: 5px;
}
.course-author-main-area-wrap-jbd {
  text-align: center;
  background: #fff;
  box-shadow: 5px 10px 30px rgb(182 182 182 / 15%);
  margin-top: 50px;
  padding: 25px;
  border-radius: 10px;
}

.course-author-main-area-wrap-jbd {
  text-align: center;
  background: #fff;
  box-shadow: 5px 10px 30px rgba(182, 182, 182, 0.15);
  margin-top: 20px;
  padding: 25px;
  border-radius: 10px;
}

.course-author-main-area-wrap-jbd h5 {
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.5;
}

.course-author-main-area-wrap-jbd i {
  color: #8a8b95;
}

.course-author-main-area-wrap-jbd h6 {
  font-weight: 600;
  font-size: 18px;
  margin-top: 7px;
  color: #292d32;
}

.additional-info-main-area-hbgjfkdl {
  text-align: left;
  p {
    margin-bottom: 10px;
    color: #8a8b95;
  }
}

.card-main-area-course-page-main-card.card {
  flex-direction: row;
  border: none !important;
  background: #fff;
  box-shadow: 5px 10px 30px rgb(182 182 182 / 15%);
  border-radius: 10px;
  padding: 20px 25px;
  img.card-img-left {
    width: 20%;
  }
  .card-body {
    width: 86%;
    flex: unset;
    .card-title.h5 {
      h3 {
        font-weight: 700;
      }
    }
  }
}
.details-page-inner-main-wrap {
  padding: 40px 0;
}
