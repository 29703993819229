.lafarge-landing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lafarge-landing-header-right > button {
  border: 2px solid #017d3c;
  color: #017d3c;
  padding: 10px 25px;
  border-radius: 10px;
  font-weight: 600;
  background: #fff;
  &:hover {
    background: #017d3c;
    color: #fff;
  }
}
.lafarge-course-ware-header {
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin-bottom: 0;
    padding-left: 0;
    li {
      list-style: none;
      position: relative;
      a {
        color: #1b3f73;
        font-size: 14px;
        font-weight: 500;
        &.active-lafarge-link {
          color: #017d3c;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background: #017d3c;
            bottom: -30px;
            left: 0;
          }
        }
      }
    }
  }
}
.lafarge-courseware-header {
  padding: 20px 0;
  background: #ffffff;
  box-shadow: 5px 10px 30px rgb(182 182 182 / 15%);
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal.show {
  z-index: 9999999;
}
