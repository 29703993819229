* {
  margin: 0;
  padding: 0;
}
body {
  font-size: 13px;
  background: #fafafa;
}
a {
  text-decoration: none;
  color: unset;
  cursor: pointer;
}
h1 {
  font-weight: 600;
  color: #333 !important;
}
h2 {
  font-weight: 600;
  color: #333 !important;
}
h3 {
  font-weight: 600;
  color: #333 !important;
}
h4 {
  font-weight: 600;
  color: #333 !important;
}
h5 {
  font-weight: 600;
  color: #333 !important;
}
h6 {
  font-weight: 500;
  color: #333 !important;
}
.btn-primary {
  color: unset;
  background: unset;
  border: unset;
}
.student-profile-update-tgbdhsjka button {
  border: 1px solid transparent;
  color: #fff;
}
.student-profile-update-tgbdhsjka button:hover {
  border: 1px solid #5756d8;
  color: #5756d8;
}
.title > h3 {
  margin-bottom: 10px;
  font-weight: 600;
}
.react-responsive-modal-modal h2 {
  padding-top: 20px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
}
.mb-3.jkfhgsuhgjbygbhvdgb input {
  display: block;
}
.mb-3.jkfhgsuhgjbygbhvdgb label {
  border-style: dashed;
  border-width: 2px;
  padding: 5px 20px;
  display: block;
  border-radius: 10px;
  border-color: #573b81;
}
.navbar-item-single-main-area-jddiu {
  overflow-y: scroll;
  height: 85vh;
  width: 100%;
}
/* width */
.navbar-item-single-main-area-jddiu::-webkit-scrollbar {
  width: 5px;
}
.navbar-item-single-main-area-jddiu > ul {
  /* overflow-y: scroll; */
  height: 100%;
  padding-top: 20px;
}
.navbar-item-single-main-area-jddiu ul::-webkit-scrollbar {
  width: 5px;
}
/* width */
.navbar-item-single-main-area-jddiu ul::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.navbar-item-single-main-area-jddiu ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.navbar-item-single-main-area-jddiu ul::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.navbar-item-single-main-area-jddiu ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Toastify {
  position: fixed !important;
  z-index: 99999 !important;
}

.hero-left-image {
  min-height: 440px !important;
}

img.course-homeIcon-abcd {
  height: 35px !important;
}

.course-sidebar-single-item-left-sdfghcjv.easfv {
  width: 35%;
}
table.table.budget-table.table-borderless > tbody > tr > td:first-child {
  text-align: center;
}
.table > :not(caption) > * > * {
  padding-left: 25px;
}
.result-page-top.kduhjnfjgnjvduybghvjk > * > * > button {
  background: #6043b2;
}

.author {
  height: 71px;
  width: 70px;
  /* border-radius: 36%; */
}
.dashboard-sidebar-nav-area.showNavbar > button {
  display: none;
}

.form-input-pass-show {
  position: relative;
  span.show-class {
    position: absolute;
    top: 13px;
    right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .react-responsive-modal-modal.lafargeModal {
    max-width: unset !important;
    width: 330px !important;
  }
  .popup-modal-main-wrap {
    padding: 30px 10px !important;
    text-align: center;
  }
  .react-responsive-modal-modal.lafargeModal .lafarge-registration-title p {
    font-size: 13px !important;
  }
  .course-details-content-main-area h2 {
    margin-top: 20px;
    font-weight: 700;
    font-size: 30px !important;
    line-height: 40px !important;
  }
  .react-responsive-modal-modal.lafargeModal .lafarge-registration-title h3 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .react-responsive-modal-modal.lafargeModal .lafarge-registration-title {
    margin-bottom: 14px;
    text-align: left;
  }
}
