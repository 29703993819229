.promocode-main-area-wrap {
  background: #5756d8;
  padding: 10px;
  text-align: center;
  .promocode-inner-wrap {
    position: relative;
    img {
      position: absolute;
      right: 0;
      top: 0;
      width: 18px;
    }
    h6 {
      color: #fff !important;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
