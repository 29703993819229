.academic-right-single-item {
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 14px 0;
}
.hero-slider-content-right.category-page h6 {
  font-size: 20px;
  color: #5756d8;
  font-weight: 600;
}
.category-inner-area-main-wrap .business-clases-hover-part > * > p {
  color: #fff;
  font-size: 12px;
  margin-bottom: 20px;
}
.category-inner-area-main-wrap .business-clases-hover-part-bottom-link > a {
  font-size: 16px;
}
.category-inner-area-main-wrap .business-clases-main-part-top h3 {
  color: #fff;
  font-size: 14px;
}
.category-inner-area-main-wrap .business-clases-main-part-bottom h4 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
}
.category-inner-area-main-wrap .business-clases-main-part-bottom-inner-left h6 {
  font-size: 14px;
}
.category-inner-area-main-wrap .business-clases-single-item-wrap {
  margin-bottom: 20px;
}
.search-area-input-field-and-button {
  width: 100%;
  height: 50px;
  display: flex;
  gap: 15px;
  margin-bottom: 0px;
  align-items: center;
  .search-area-input-field-box-wrap {
    width: 80%;
    height: 40px;
    overflow: hidden;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 10px;
    i {
      position: absolute;
      left: 15px;
      z-index: 99;
      top: 30%;
      color: #8a8b95;
      font-size: 18px;
    }
    input {
      width: 100%;
      height: 100%;
      padding-left: 40px;
      border: none;
    }
  }
  button {
    width: 20%;
    height: 40px;
    border: 1px solid #ddd;
    background: #5756d8;
    color: #fff;
    border-radius: 10px;
  }
}
.course-filtering-area-wrap-inner-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 5px;
  padding-top: 5px;
  span {
    display: flex;
    gap: 10px;
    color: #858690;
    font-size: 12px;
  }
}

.course-filtering-area-wrap.courseware-common-tabs {
  ul.tab-list-grid-course-data {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-left: 0;
    li.react-tabs__tab {
      padding: 0px !important;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        line-height: 1;
      }
    }
  }
}
