.lafarge-landing-header-main-wrap {
  padding: 10px 0;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(148, 149, 158, 0.15);
}
.lafarge-hero-main-wrap {
  min-height: 92vh;
  display: flex;
  align-items: center;
  position: relative;
  background-position: center !important;
  background-size: cover !important;
  text-align: center;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2e2e2ed4;
  }
  * {
    color: #fff;
    position: relative;
    z-index: 1;
  }
  h3 {
    color: #fff !important;

    font-size: 64px;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
    letter-spacing: 0.75px;
    padding: 0px 30px;
  }
  button {
    border: none !important;
    padding: 12px 25px !important;
    background: #017d3c;
    display: inline-block;
    margin-top: 30px;
    border-radius: 10px;
    font-size: 18px;
  }
  a {
    border: none !important;
    padding: 12px 25px !important;
    background: #017d3c;
    display: inline-block;
    margin-top: 50px;
    border-radius: 10px;
    font-size: 18px;
    &:hover {
      background: #017d33;
      color: #fff;
    }
  }
}
.lafarge-training-inner-wrap {
  background: #fff;
  margin-top: 30px;
  padding: 70px 50px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 10px 30px rgba(196, 196, 196, 0.15);
  border-radius: 10px;
  p {
    font-size: 18px !important;
    line-height: 30px;
  }
  ul {
    li {
      font-size: 16px !important;
      line-height: 28px;
    }
  }
  .form-control {
    background: #f5f9ff;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
  }
  .fill-in-the-gaps {
    width: 30px;
    display: inline-block;
    border-bottom: 1px solid gray;
    margin: 0px 5px;
  }
  .lafarge-training-items-wrap {
    display: flex;

    grid-gap: 15px;
    gap: 15px;
    padding: 70px 0;
    &.ptainig-page {
      padding-top: 15px;
      .lafarge-training-content {
        img {
          display: block;
          margin-bottom: 20px;
        }
      }
    }
    .lafarge-training-item {
      background: #fbd2d6;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 300px;
      border-radius: 10px;
      width: 24%;
      margin-bottom: 15px;
      cursor: pointer;
      h5 {
        margin-top: 35px !important;
      }
      span {
        text-decoration: underline;
        display: inline-block;
        margin-top: 25px;
      }
      &.concept-1 {
        background: #fbd2d6;
      }
      &.concept-2 {
        background: #d2d6fb;
      }
      &.concept-3 {
        background: #d2f6fb;
      }
      &.concept-4 {
        background: #cff1cc;
        svg {
          position: relative;
          right: -10px;
        }
      }
    }
  }
}
.lafarge-training-header-title {
  margin-bottom: 30px;
  h3 {
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 700;
  }
}
.react-responsive-modal-modal.lafarge-training-modal {
  max-width: 991px;
  width: 500px;
  padding: 40px;
  position: relative;
  z-index: 1;
  min-height: 350px;
  h3 {
    margin-bottom: 15px;
    font-weight: 800;
    font-size: 32px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
  }
  .training-popup-bg {
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 350px;
    z-index: -1;
    img {
      width: 100%;
      //   opacity: 0.3;
      //   height: 250px;
    }
  }
}

.lafarge-training-footer-button {
  text-align: center;
  * {
    border: none;
    padding: 12px 30px;
    display: inline-block;
    // background: #017d3c;
    font-weight: 700;
    color: #017d3c;
    border-radius: 10px;
    min-width: 150px;
    border: 2px solid #017d3c;
    &:hover {
      color: #fff;
      background: #017d3c;
    }
  }
  &.lafarge-details {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    a:first-child {
      background: #ffffff;
      color: #017d3c;
      border: 2px solid #017d3c;
    }
  }
}
.why-do-we-need-bottom-image {
  width: 100%;
  position: relative;
  min-height: 340px;
  display: flex;
  align-items: center;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2e2e2eb5;
    z-index: 1;
  }
  h5 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
    margin-top: 15px;
    position: absolute;
    z-index: 9;
    color: #fff !important;
    text-align: center;
    padding: 20px;
  }
  img {
    width: 100%;
    position: relative;
    z-index: 1;
  }
}
.tab-content-right-part-wrap.tab-content {
  height: 100%;
  // padding: 40px 30px;
  border-radius: 10px;
  .tab-content-right-single-tabs.tab-pane {
    padding: 40px 40px;
    background: #017d3c;
    height: 100%;
    border-radius: 10px;
    &:nth-child(1) {
      background-color: #d2d6fb;
    }
    &:nth-child(2) {
      background-color: #c3e6e0;
    }
    &:nth-child(3) {
      background-color: #fbe3d2;
    }
    &:nth-child(4) {
      background-color: #d2f6fb;
    }
    &:nth-child(5) {
      background-color: #cff1cc;
    }
    &:nth-child(6) {
      background-color: #fbd2d6;
    }
    &:nth-child(7) {
      background-color: #e8e4f2;
    }
  }
  p {
    color: #000;
    font-size: 14px;
    line-height: 24px;
    line-height: 30px;
  }
}
.tab-content-part-part-wrap > * {
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  margin-bottom: 15px;

  a {
    padding: 15px;
    color: #000000;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    i {
      font-size: 20px;
      color: #000084;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.negotiation-main-area-tabs-wrap.mb-5 {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #017d3c;
    i {
      font-size: 20px;
      color: #fff;
    }
  }
}
.tab-content-right-single-tabs {
  h5 {
    color: #000 !important;
    margin-bottom: 25px;
    font-size: 32px;
  }
  img {
    width: 100%;
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      list-style: none;
      color: #000;
      display: flex;
      gap: 8px;
      margin-bottom: 10px;
      i {
        position: relative;
        top: 3px;
      }
    }
  }
}
.tab-content-right-part-wrap.bottom-tabs.tab-content {
  background: #fff;
  height: 100%;
  padding: 0px;
  border-radius: 0px;
}
.negotiation-mrthod-content-area p {
  font-size: 14px;
  line-height: 30px;
}
.lafarge-exercise-ques-main-wrap {
  margin-top: 50px;
  .lafarge-exercise-single-item {
    .lafarge-exercise-single-item-inner {
      background: #ffffff;
      border: 1px solid #e8e8e8;
      box-shadow: 0px 5px 15px rgb(196 196 196 / 15%);
      border-radius: 10px;
      padding: 15px 40px;
      .lafarge-exercise-single-item-inner-left {
        display: flex;
        gap: 20px;
        align-items: center;
        > h6 {
          width: 40px;
        }
        p {
          width: 92%;
        }
      }
      .lafarge-exercise-single-item-inner-right {
        padding-left: 25px;
        position: relative;
        .radio-buttons {
          padding-left: 30px;
          // overflow: hidden;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 100%;
          background: #017d3c;
          border-radius: 10px;
        }
        label {
          cursor: pointer;
          position: relative;
          font-size: 16px;
          padding-left: 10px;
        }
      }
    }
    .lafarge-ques-submit-button {
      display: flex;
      justify-content: center;
      margin: 25px 0;
      button.lafarge-ques-submit-button-inner {
        border: 2px solid #017d3c;
        color: #017d3c;
        padding: 10px 50px;
        background: #fff;
        font-weight: 600;
        font-size: 18px;
        border-radius: 10px;
        a.lafarge-ques-submit-button-inner {
          border: 2px solid #017d3c;
          color: #017d3c;
          padding: 10px 50px;
          background: #fff;
          font-weight: 600;
          font-size: 18px;
          border-radius: 10px;
          &:hover {
            background: #017d3c;
            color: #fff;
          }
        }
        &:hover {
          background: #017d3c;
          color: #fff;
        }
      }
      .lafarge-ques-submit-button-inner {
        border: 2px solid #017d3c !important;
        color: #017d3c;
        padding: 10px 50px;
        background: #fff;
        font-weight: 600;
        font-size: 18px;
        border-radius: 10px;
        &:hover {
          background: #017d3c;
          color: #fff;
        }
      }
    }
  }
}
.lafarge-single-rank-label {
  display: grid;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: auto auto auto;
  .form-group {
    // width: 25%;
    position: relative;
    padding-right: 10px;
    &:last-child {
      &:after {
        content: "";
        position: absolute;
        top: -40px;
        left: -80px;
        width: 2px;
        height: 70px;
        background: #dadaf6;
        border-radius: 10px;
      }
    }
  }
  h6 {
    display: block;
    min-width: 250px;
  }
}
.lafarge-video-upload-right-wrap {
  width: 100%;
  height: 100%;
  padding: 40px;
  input {
    display: none;
  }
  label {
    width: 100%;
    min-height: 200px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    img {
      width: unset;
      height: 140px;
    }
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
}
.lafarge-exercise-single-item-inner-right.fill-inthe-gap-field input {
  max-width: 250px;
}
.success-main-page-area-wrap {
  background: #fff;
  margin-top: 30px;
  padding: 70px 50px;
  // border: 1px solid #ebebeb;
  // box-shadow: 0px 10px 30px rgb(196 196 196 / 15%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // min-height: 80vh;
  .success-main-page-area {
    img {
      display: inline-block;
      margin-bottom: 40px;
      height: 200px;
    }
    h3 {
      margin-bottom: 25px;
      color: #6a7187 !important;
      font-size: 40px;
      font-weight: 700;
    }

    a {
      border: 2px solid #017d3c;
      color: #017d3c;
      padding: 10px 50px;
      background: #fff;
      font-weight: 600;
      font-size: 18px;
      border-radius: 10px;
      display: inline-block;
      margin-top: 50px;
    }
  }
}
@keyframes customEnterOverlayAnimation {
  0% {
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
  }
  100% {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }
}
@keyframes customLeaveOverlayAnimation {
  0% {
    transform: scale(1);
    transition: 0.3s ease-in-out;
  }
  100% {
    transform: scale(0);
    transition: 0.3s ease-in-out;
  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0);
    transition: 0.3s ease-in-out;
  }
  100% {
    transform: scale(1);
    transition: 0.3s ease-in-out;
  }
}
@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(1);
    transition: 0.3s ease-in-out;
  }
  100% {
    transform: scale(0);
    transition: 0.3s ease-in-out;
  }
}

.lafarge-contect-form-popup {
  min-height: 250px;
  position: relative;
  .lafarge-contect-form-popup-header {
    padding-bottom: 15px;
  }
  img {
    height: 120px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .modal-header {
    border: none;
    align-items: stretch;
    padding-bottom: 0;
    .modal-title.h4 {
      padding: 40px;
      padding-bottom: 0;
      font-size: 36px;
      font-weight: 700;
    }
  }
  .lafarge-contect-form-popup-body {
    padding-left: 60px !important;
    p {
      font-size: 18px;
      line-height: 34px;
    }
  }
}

// ============================

.lafarge-training-inner-wrap.file-upload-container {
  min-height: 70vh;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  padding-top: 15px;
  .lafarge-exercise-single-item-inner {
    border: none;
    box-shadow: 0px 10px 30px #c4c4c400;
    .lafarge-exercise-single-item-inner-right::after {
      display: none;
    }
  }
  .lafarge-exercise-single-item-inner-right label {
    padding-left: 0 !important;
  }
  .lafarge-exercise-single-item-inner-right {
    padding-left: 0 !important;
    padding-left: 0 !important;
    border: 1px dashed #017d3c;
    height: 100%;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
    background: #017d3c06;
    video {
      height: 100%;
    }
    &.preview-part {
      background: #fff;
      border-color: #8080803d;
      border-style: solid;
      .lafarge-video-upload-right-wrap {
        width: 100%;
        height: 100%;
        padding: 0px;
      }
      h5 {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        line-height: 24px;
      }
    }
  }
}
.lafarge-training-inner-wrap.popup-file-option-select {
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  border: none;
  box-shadow: 0px 0px 0px;
  padding: 0px 0px;
  margin-top: 0;
  .lafarge-exercise-single-item-inner {
    border: none;
    box-shadow: 0px 10px 30px #c4c4c400;
    .lafarge-exercise-single-item-inner-right {
      padding-left: 0px !important;
      &::after {
        display: none;
      }
    }
  }
  .lafarge-exercise-single-item-inner {
    padding: 0 0px !important;
  }
  .lafarge-exercise-ques-main-wrap {
    margin-top: 10px;
  }
  .lafarge-exercise-ques-main-wrap {
    .lafarge-ques-submit-button {
      justify-content: center !important;
      margin-top: 40px;
    }
  }
  .lafarge-ques-items-wrap {
    width: 100%;
  }
  button:focus-visible {
    outline: none;
  }
  .lafarge-ques-submit-button {
    margin-bottom: 0 !important;
  }
}
.as-organization-content-area {
  h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 15px;
  }
}
.video-remove-btn-lafarge button {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 10px;
  z-index: 9;
  font-size: 22px;
  border: none;
  color: #fff;
  border-radius: 50%;
  top: 10px;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 16px;
  }
}
.video-remove-btn-lafarge-right-prev-area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modal-inner-design {
  min-height: 200px;
  padding: 15px;
  > * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 200px;
    button {
      &:first-child {
        margin-right: 10px;
        background: #017d3c;
        color: #fff;
        border: 2px solid transparent;
        &:hover {
          color: #017d3c;
          background: #fff;
          border: 2px solid #017d3c;
        }
      }
      &:last-child {
        margin-left: 10px;
        border: 2px solid #017d3c;
        background: #fff;
        color: #017d3c;
        &:hover {
          background: #017d3c;
          color: #fff;
        }
      }
    }
  }
}

.guidlines-procceed-button {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  .lafarge-ques-submit-button-inner {
    border: 2px solid #017d3c;
    background: #017d3c;
    padding: 10px 50px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    border-radius: 10px;
  }
}
.lafarge-training-guideline-inner-wrap {
  ul {
    li {
      list-style: decimal;
    }
  }
  h6 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}
.lafarge-ques-next-section-wrap-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 40px;
}

.lafarge-training-content {
  img {
    width: 100%;
    border-radius: 10px;
  }
}
.why-do-we-need-content-area > p {
  margin-bottom: 40px;
}

.negotiation-mrthod-content-area > h4 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 60px;
}
.registration-form-submit-button {
  margin-top: 40px;
}
.lafarge-video-area-wrap {
  iframe {
    border-radius: 10px;
    min-height: 390px;
    overflow: hidden;
    width: 100%;
  }
}
button.react-responsive-modal-closeButton {
  display: none !important;
}
button.nav-item {
  background: none;
  text-align: left;
  padding: 7px 0;
  &.active {
    background: #017d3c;
    span {
      color: #fff;
    }
  }
  span {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    line-height: 24px;
    font-size: 16px;
  }
}

.video-uploading-loader > button {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #017d3c !important;
  background: none !important;
}

.the-concept-image-overlay {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 25px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 0 !important;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}


.profile-hjkdjfbajbknyf tr { 
th {
  font-size: 18px;
  color: black;
}  
  td  {
  font-size: 18px;
  color: black;
}
}
