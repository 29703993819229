.cart-main-area-wrap-cart-body-gfhdjskujcm {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    p {
      margin-top: 25px;
    }
  }
}
.cart-main-area-wrap-cart-body-gfhdjskujcm.uyghbjnkiouhjn p {
  font-size: 16px;
  a {
    text-decoration: none;
    color: #4543d2;
  }
}
.cart-header-title h4 {
  font-weight: 700;
  font-size: 30px;
  /* text-align: center; */
  margin-bottom: 10px;
}
